import React from 'react';
import Typical from 'react-typical'

export default function Profile() {
  return (
    <div className='profile-container'>
        <div className='profile-parent'>
            <div className='profile-details'>
                <div className='colz'>
                    <a href='www.youtube.com'>
                        <i className='fa fa-facebook-square'></i>
                    </a>
                    <a href='https://www.linkedin.com'>
                        <i className='fa fa-google-plus-square'></i>
                    </a>
                    <a href='https://www.linkedin.com/in/abhay-dere-a90aa2178/'>
                        <i className='fa fa-linkedin'></i>
                    </a>
                    <a href='https://www.instagram.com/abhay.dere/'>
                        <i className='fa fa-instagram'></i>
                    </a>
                </div>

            
            <div className='profile-details-name'>
                <span className='primary-text'>
                    {" "}
                    Hello, I'm <span className='highlighted-text'>Abhay</span>
                </span>
            </div>
            <div className='profile-details-role'>
                <span className='primary-text'>
                    {" "}
                    <h1>
                        {" "}
                        Enthusiastic Dev
                        <Typical loop={Infinity}
                        steps={[
                            "Enthusiastic Dev ",
                            1000
                        ]}/>
                    </h1>
                </span> 
            </div>
            </div>

        </div>
    </div>
  )
}
